import { get } from "./api";

export const hlApi = {
    

    getHomeLearningReport: async (filter) => {
        let url = `homelearning/getHomeLearningReport`;
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getHomeLearningReportDetailed: async (filter) => {
        let url = `homelearning/getHomeLearningReportDetailed`;
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

};
