import React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTruancyReport } from "../../store/dashboard/attendanceSlice";
import DownloadCSV from "../general/DownloadCSV";
import { Loader } from "../general/Loader";
import "./Reports.scss";

export const TruancyReport = () => {
    const dispatch = useDispatch();
    const truancyReport = useSelector((state) => state.attendance.truancyReport);

    useEffect(() => {
            dispatch(getTruancyReport());
         }, [dispatch]);


    const render = () => {
        if (truancyReport.status === "loading") {
            return <Loader />;
        } else if (truancyReport.status === "error") {
            return <div>Error: {truancyReport.error}</div>;
        } else {
            return (
                <div>
                    <h2>This Week's Truancy Report</h2>
                    <p>This report shows students who have some present marks during a day, but also some unknown marks.</p>
                    <div className="no-print">
                        <DownloadCSV
                            data={truancyReport.data}
                            fileName={"truancy-report"}
                            header={["stuid", "markdate", "firstname", "lastname","overallattendance", "tutorgroup", "marks_detail","amreg","present", "absent"]}
                            caption="Download CSV" />
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Mark Date</th>
                                <th>Student</th>
                                <th>Attendance</th>
                                <th>Details</th>
                                <th>AM Reg Mark</th>
                                <th>Present</th>
                                <th>Absent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {truancyReport.data &&
                                truancyReport.data.map((item, index) => {
                                    let datelogged = new Date(item.markdate);
                                    return (
                                        <tr key={index}>
                                            <td>{datelogged.toDateString()}</td>
                                            <td>
                                                <Link to={`/Dashboard/Students/${item.stuid}`}>
                                                    {item.firstname} {item.lastname} ({item.tutorgroup})
                                                </Link>
                                            </td>
                                            <td>{item.overallattendance}</td>
                                            <td>{item.marks_detail}</td>
                                            <td>{item.am_reg_mark}</td>
                                            <td>{item.present_count}</td>
                                            <td>{item.absent_count}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    return render();
};
