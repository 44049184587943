import React from "react";
import { NavItem } from "./../navigation/NavItem";
import { NavSep } from "./../navigation/NavSep";
//import { useParams, useLocation, Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import "./Reports.scss";

export const Reports = () => {
    return (
        <div>
            <div className="no-print">
                <h1>Reports</h1>
                <p>Standalone reports that are not 'dashboards'. Each report is a separate entity, and is not changed with dashboard filters.</p>
                <nav className="no-print">
                    <ul className="nav nav-pills">
                        <NavItem label="Lesson Lates Report" linkto="LessonAttendance" />
                        <NavItem label="Truancy Report" linkto="TruancyReport" />
                        <NavItem label="Session Attendance Report" linkto="SessionAttendance" />
                        <NavItem label="Home Learning Report" linkto="HomeLearning" />
                    </ul>
                </nav>
            </div>
            <hr className="no-print" />
            <section className="dashboard-body">
                <Outlet />
            </section>
        </div>
    );
};
