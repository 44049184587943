import React from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavItem } from "./../navigation/NavItem";
import { NavSep } from "./../navigation/NavSep";
import { useEffect } from "react";

import { addFilter, removeFilter, removeExcludeFilter, setRoute, setType, setId, loadDashboard } from "./../../store/dashboard/dashSlice";

export const Dashboard = ({ type }) => {
    let { id } = useParams();
    let location = useLocation();
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.dash.filters);
    const excludefilters = useSelector((state) => state.dash.excludefilters);
    const relatedBoards = useSelector((state) => state.dash.relatedBoards);
    const name = useSelector((state) => state.dash.name);

    // Get the dashboard data from the store
    useEffect(() => {
        dispatch(setRoute(location.pathname));
        dispatch(setType(type));
        dispatch(setId(id));
        dispatch(loadDashboard({ type, id }));
    }, [dispatch, location.pathname, type, id]);

    const getFilters = () => {
        if (filters.length === 0) return null;
        const f = filters.map((filter, index) => (
            <button key={index} className="btn btn-success me-2" title={filter.key} onClick={() => onRemoveFilter(filter.key, filter.value)}>
                {filter.value}
            </button>
        ));
        return (
            <div className="dashboard-filters mb-3">
                <span className="me-2">Include filters:</span>
                {f}
            </div>
        );
    };


    const getExcludeFilters = () => {
        if (excludefilters.length === 0) return null;
        const f = excludefilters.map((filter, index) => (
            <button key={index} className="btn btn-danger me-2" title={filter.key} onClick={() => onRemoveExcludeFilter(filter.key, filter.value)}>
                {filter.value}
            </button>
        ));
        return (
            <div className="dashboard-filters mb-3">
                <span className="me-2">Exclude filters:</span>
                {f}
            </div>
        );
    };

    const onAddFilter = (key, value) => {
        dispatch(addFilter({ key, value }));
    };

    const onRemoveFilter = (key, value) => {
        dispatch(removeFilter({ key, value }));
    };

    const onRemoveExcludeFilter = (key, value) => {
        dispatch(removeExcludeFilter({key, value}));
    };

    const dashboardName = () => {
        return name ? name : "";
    };

    const getRelatedBoards = () => {
        if (relatedBoards.length === 0) return null;
        const f = relatedBoards.map((board, index, arr) => (
            <span key={index}>
                <Link draggable="false" className="prevent-select" to={board.route}>
                    {board.name}
                </Link>
                &nbsp;{index + 1 !== arr.length ? `|` : ``}&nbsp;
            </span>
        ));

        return (
            <div>
                <span className="text-primary fw-bold">Related boards: </span>
                {f}
            </div>
        );
    };

    return (
        <div>
            <div className="">
                <h1>{dashboardName()}</h1>
                <div className="no-print">{getRelatedBoards()}</div>
                <hr className="no-print"/>
                <nav className="no-print">
                    <ul className="nav nav-pills">
                        <NavItem label="Conduct Overview" linkto="Overview" />
                        <NavSep />
                        <NavItem label="Behaviour" linkto="Behaviour" />
                        <NavItem label="Behaviour Log" linkto="BehaviourLog" />
                        <NavItem label="Behaviour table" linkto="BehaviourTable" /> 
                        <NavSep />
                        <NavItem label="Achievement" linkto="Achievement" />
                        <NavItem label="Achievement Log" linkto="AchievementLog" />
                        <NavItem label="Achievement table" linkto="AchievementTable" /> 
                        <NavSep />
                        <NavItem label="Attendance" linkto="Attendance" />
                        <NavItem label="Attendance Log" linkto="AttendanceLog" />
                    </ul>
                </nav>
                <hr className="no-print"/>
                <div className="dashboard-quickfilters mb-3 no-print">
                    <span className="me-2">Quick filters:</span>
{/*                     <button className="btn btn-outline-secondary me-2" onClick={() => onAddFilter("conductdate", "Today")}>
                        Today
                    </button> */}
                    <button className="btn btn-outline-secondary me-2" onClick={() => onAddFilter("conductdate", "Yesterday")}>
                        Yesterday
                    </button>
                    <button className="btn btn-outline-secondary me-2" onClick={() => onAddFilter("conductdate", "This Week")}>
                        This Week
                    </button>
                    <button className="btn btn-outline-secondary me-2" onClick={() => onAddFilter("conductdate", "Last Week")}>
                        Last Week
                    </button>
                    <button className="btn btn-outline-secondary me-2" onClick={() => onAddFilter("conductdate", "This Month")}>
                        This Month
                    </button>
                    <button className="btn btn-outline-secondary me-2" onClick={() => onAddFilter("conductdate", "Last Month")}>
                        Last Month
                    </button>
                </div>
                {getFilters()}
                {getExcludeFilters()}
                <hr className="no-print"/>
                <section className="dashboard-body">
                    <Outlet />
                </section>
            </div>
        </div>
    );
};
