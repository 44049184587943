import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hlApi } from "../../api/api.hl";

const initialState = {
    homeLearningReport: {
        data: [],
        detailed: [],
        days: 7,
        incidents: 0,
        order: "",
        status: "idle",
        error: "",
    },
};

export const getHomeLearningReportDetailed = createAsyncThunk("hl/getHomeLearningReportDetailed", async (payload, thunkAPI) => {
    const apiResponse = await hlApi.getHomeLearningReportDetailed(payload);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getHomeLearningReport = createAsyncThunk("hl/getHomeLearningReport", async (payload, thunkAPI) => {
    const apiResponse = await hlApi.getHomeLearningReport(payload);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const homeLearningSlice = createSlice({
    name: "homelearning",
    initialState,
    reducers: {
        setReportDays: (state, action) => {
            state.homeLearningReport.days = action.payload;
        },
        setReportIncidents: (state, action) => {
            state.homeLearningReport.incidents = action.payload;
        },
        setReportOrder: (state, action) => {
            state.homeLearningReport.order = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getHomeLearningReportDetailed.pending, (state) => {
                state.homeLearningReport.status = "loading";
            })
            .addCase(getHomeLearningReportDetailed.fulfilled, (state, action) => {
                state.homeLearningReport.status = "idle";
                state.homeLearningReport.detailed = action.payload;
            })
            .addCase(getHomeLearningReportDetailed.rejected, (state, action) => {
                state.homeLearningReport.status = "error";
                state.homeLearningReport.error = action.payload;
            })
            .addCase(getHomeLearningReport.pending, (state) => {
                state.homeLearningReport.status = "loading";
            })
            .addCase(getHomeLearningReport.fulfilled, (state, action) => {
                state.homeLearningReport.status = "idle";
                state.homeLearningReport.data = action.payload;
            })
            .addCase(getHomeLearningReport.rejected, (state, action) => {
                state.homeLearningReport.status = "error";
                state.homeLearningReport.error = action.payload;
            }
        );
    },
});

export const { setReportDays, setReportOrder, setReportIncidents } = homeLearningSlice.actions;
export default homeLearningSlice.reducer;
