import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from "chart.js";
import { useDispatch } from "react-redux";
import { addFilter , addExcludeFilter} from "./../../store/dashboard/dashSlice";
import { Line } from "react-chartjs-2";
import "./DashboardChart.scss";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);

export const LineChart = ({ title, filterkey, data, double }) => {
    const dispatch = useDispatch();

    const colours = [
        "rgba(128, 0, 128, .8)", 
        "rgba(255, 140, 0, .8)",   
        "rgba(46, 139, 87, .8)",   
        "rgba(30, 144, 255, .8)",  
        "rgba(0, 191, 255, .8)",   
        "rgba(255, 165, 0, .8)",     
        "rgba(139, 0, 139, .8)",   
        "rgba(220, 20, 60, .8)",   
        "rgba(255, 69, 0, .8)",    
        "rgba(105, 105, 105, .8)", 
        "rgba(169, 169, 169, .8)" 
    ]
    const onAddFilter = (value) => {
        dispatch(addFilter({ key: filterkey, value }));
    };
    const onContext = (e) => {    
        if (e.target.tagName === "CANVAS") {
            e.preventDefault();
        }
    };

    const onAddExcludeFilter = (value) => {
        dispatch(addExcludeFilter({ key: filterkey, value }));
    };

    const getChartData = () => {
        if (!data || data.length === 0) {
            return null;
        }
        const ds = data.map((item, index) => {
            return {
                label: item.name,
                data: item.values,
                borderColor: colours[index],
                backgroundColor: colours[index],
            };
        });
        const labels = []
        for (let i = 0; i < data[0].labels.length; i++) {
            labels.push(data[0].labels[i]);
        }
        if (data.length > 1) {
            for (let i = 0; i < data[1].labels.length; i++) {
                if (!labels.includes(data[1].labels[i]))
                    labels.push(data[1].labels[i]);
            }
        }
        const chartData = {
            labels: labels,
            datasets: ds,
        };
        return chartData;
    }

    

    const options = {
        animation: false,
        plugins: {
            responsive: true,
            legend: {
                display: true,
                position: "bottom",
                align: "center",
                labels: {
                    color: "black",
                },
            },
            title: { display: false },
        },
        events: ["click", "contextmenu", "mousemove"],
        onClick: (e, ele) => {
            //e.chart.tooltip.setActiveElements([]);
            if (ele.length === 0) return;
            if (e.type === "contextmenu") {
                onAddExcludeFilter(data[0].labels[ele[0].index]);
                
            }
            else if (e.type === "click") {
                onAddFilter(data[0].labels[ele[0].index]);
            }
        },
    };

    const getCssClass = () => {
        if (double) {
            return "dashboard-chart col-sm-12 col-md-12 col-lg-12 col-xxl-12 no-print-break";
        } else {
            return "dashboard-chart col-sm-12 col-md-12 col-lg-8 col-xxl-6 no-print-break";
        }
    };

    return (
        <div className={getCssClass()} onContextMenu={onContext}>
            <div className="dashboard-chart-inner card text-primary">
                <h4 className="text-center">{title}</h4>
                {data === null || data.length === 0 ? (<></>) : (<Line options={options} data={getChartData()} />)}
            </div>
        </div>
    );
};
