import { get } from "./api";

export const attendanceApi = {
    
    getAttendanceLog: async (filter, exclude, order, present) => {
        let url = `attendance/log`;
        return get(url, "json", {filter, exclude, order, present}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getLateReport: async (filter) => {
        let url = `attendance/latereport`;
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getLateReportDetailed: async (filter) => {
        let url = `attendance/latereportdetailed`;
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getAttendanceByType: async (filter, exclude) => {
        let url = `attendance/bytype`;
        return get(url, "json", {filter, exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getAttendanceByWeek: async (filter, exclude) => {
        let url = `attendance/byweek`;
        return get(url, "json", {filter, exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getTruancyReport: async () => {
        let url = `attendance/truancy`;
        return get(url, "json").then((res) => {
            if (res) {
                return res;
            }
        });
    },

};
