import React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getHomeLearningReport, getHomeLearningReportDetailed } from "../../store/dashboard/homeLearningSlice";
import DownloadCSV from "../general/DownloadCSV";
import { Loader } from "../general/Loader";
import { setReportDays, setReportIncidents, setReportOrder } from "../../store/dashboard/homeLearningSlice";
import { useRef } from "react";
import "./Reports.scss";

export const HomeLearning = () => {
    const dispatch = useDispatch();

    const hlreport = useSelector((state) => {
        return state.homelearning.homeLearningReport;
    });

    const timed = useRef(null);
    const filterChange = useRef(false);

    useEffect(() => {
        if (filterChange.current) {
            if (timed.current) {
                clearTimeout(timed.current);
            }
            timed.current = setTimeout(() => {
                filterChange.current = false;
                localStorage.setItem("dash:hlreport:days", hlreport.days);
                localStorage.setItem("dash:hlreport:incidents", hlreport.incidents);
                localStorage.setItem("dash:hlreport:order", hlreport.order);
                dispatch(
                    getHomeLearningReport({
                        days: hlreport.days,
                        incidents: hlreport.incidents,
                        order: hlreport.order,
                    })
                );
                dispatch(
                    getHomeLearningReportDetailed({
                        days: hlreport.days,
                        incidents: hlreport.incidents,
                        order: hlreport.order,
                    })
                );
            }, 500);
        } else {
            dispatch(setReportDays(localStorage.getItem("dash:hlreport:days") || 7));
            dispatch(setReportIncidents(localStorage.getItem("dash:hlreport:incidents") || 0));
            dispatch(setReportOrder(localStorage.getItem("dash:hlreport:order") || ""));
            dispatch(
                getHomeLearningReport({
                    days: hlreport.days,
                    incidents: hlreport.incidents,
                    order: hlreport.order,
                })
            );
            dispatch(
                getHomeLearningReportDetailed({
                    days: hlreport.days,
                    incidents: hlreport.incidents,
                    order: hlreport.order,
                })
            );
        }
    }, [dispatch, hlreport.days, hlreport.minutes, hlreport.incidents, hlreport.order]);

    const handleDaysChange = (e) => {
        if (e.target.value && !isNaN(e.target.value)) {
            filterChange.current = true;
            dispatch(setReportDays(e.target.value));
        }
    };

    const handleIncidentsChange = (e) => {
        if (e.target.value && !isNaN(e.target.value)) {
            filterChange.current = true;
            dispatch(setReportIncidents(e.target.value));
        }
    };

    const handleOrderChange = (order) => {
        filterChange.current = true;
        dispatch(setReportOrder(order));
    };

    const render = () => {
        if (hlreport.status === "loading") {
            return <Loader />;
        } else if (hlreport.status === "error") {
            return <div>Error: {hlreport.error}</div>;
        } else {
            return (
                <div>
                    <h2>Home Learning Report</h2>
                    <p>
                        Students who have{" "}
                        <input className="latereportinput" type="number" onChange={handleIncidentsChange} value={hlreport.incidents} />{" "}
                        incidents of missed homework in the last last{" "}
                        <input className="latereportinput" type="number" onChange={handleDaysChange} value={hlreport.days} /> days
                    </p>
                    <div className="no-print">
                        <DownloadCSV
                            data={hlreport.data}
                            fileName={"attendance-report"}
                            header={["id", "firstname", "lastname", "tutor", "incidents", "points"]}
                            caption="Download CSV"
                        />
                        &nbsp;&nbsp;
                        <DownloadCSV
                            data={hlreport.detailed}
                            fileName={"attendance-report"}
                            header={[
                                "firstname",
                                "lastname",
                                "tutorgroup",
                                "description",
                                "date",
                                "points",
                                "category",
                                "staff",
                                "class",
                            ]}
                            caption="Download Detailed Report"
                        />
                        &nbsp;&nbsp;
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Student</th>
                                <th
                                    className={`dash-table-order ${hlreport.order === "minutes" ? "" : "dash-table-order-selected"}`}
                                    onClick={(e) => handleOrderChange("incidents")}
                                >
                                    Number of incidents
                                </th>
                                <th
                                    className={`dash-table-order ${hlreport.order === "minutes" ? "dash-table-order-selected" : ""}`}
                                    onClick={(e) => handleOrderChange("minutes")}
                                >
                                    Total Points
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {hlreport.data &&
                                hlreport.data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Link to={`/Dashboard/Students/${item.stuid}`}>
                                                    {item.firstname} {item.lastname} ({item.tutorgroup})
                                                </Link>
                                            </td>
                                            <td>{item.incidents}</td>
                                            <td>{item.points}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    return render();
};
